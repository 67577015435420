<template>
  <div>
    <donut-chart
      :colors="getColors"
      :labels="getLabels"
      :series="getSeries"
      class="h-full"
    />
  </div>
</template>

<script>
import DonutChart from "@/components/elements/charts/DonutChart";
import LandingStatsRepository from "@/repositories/LandingStatsRepository";

export default {
  name: "LandingDeliveryStatsDonutChart",
  components: {DonutChart},
  data: () => ({
    responseData: []
  }),
  created() {
    LandingStatsRepository
      .getDeliveryStats()
      .then((res) => this.responseData = res.data.data);
  },
  methods: {
    getLabelByStatus(status) {
      switch (status) {
        case "delivered":
          return "Delivré";
        case "undelivered":
          return "En attente";
      }
    },
    getColorByStatus(status) {
      switch (status) {
        case "delivered":
          return this.$luxcaddyConfig('home.charts.colors.primary');
        case "undelivered":
          return this.$luxcaddyConfig('home.charts.colors.secondary');
      }
    },
  },
  computed: {
    getSeries() {
      return this.responseData.flatMap(d => d.total);
    },
    getLabels() {
      return this.responseData.flatMap(d => this.getLabelByStatus(d.status));
    },
    getColors() {
      return this.responseData.flatMap(d => this.getColorByStatus(d.status));
    },
  },
}
</script>

<style scoped>

</style>
import apiService from './Base/BaseRepository';

const url = '/landing-stats';

export default {
  async getOrderStats() {
    return await apiService.get(`${url}/orders`, {showLoadingIndicator: false});
  },
  async getDeliveryStats() {
    return await apiService.get(`${url}/deliveries`, {showLoadingIndicator: false});
  },
  async getPickedProductsStats() {
    return await apiService.get(`${url}/picked-products`, {showLoadingIndicator: false});
  },
  async getNewAccountsStats() {
    return await apiService.get(`${url}/new-accounts`, {showLoadingIndicator: false});
  },
}
